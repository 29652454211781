<template>
  <div class="case-video-contaner">
    <Header></Header>
    <Nav></Nav>

    <!-- 案例详情 start -->
    <el-row class="case-box">
      <el-row class="contaner">
        <!-- 面包屑 start -->
        <el-row class="nav-box" type="flex" align="middle">
          <!-- <span class="pos">您现在的位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'tecnology' }"
              >技术中心</el-breadcrumb-item
            >
            <el-breadcrumb-item>文章详情</el-breadcrumb-item>
          </el-breadcrumb> -->
          <span class="pos">您现在的位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">
              <span class="c9">首页</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'tecnology' }"
              ><span style="font-weight: 400"
                ><span class="c9">技术中心</span></span
              ></el-breadcrumb-item
            >
            <el-breadcrumb-item
              ><span class="c3">文章详情</span></el-breadcrumb-item
            >
          </el-breadcrumb>
        </el-row>
        <!-- 面包屑 end -->
        <!-- 案例 start -->
        <el-row class="case">
          <!-- 头部 -->
          <el-row class="header">
            <p class="title">
              {{ articleMsg.essayTitle }}
            </p>
            <p class="date">
              发布时间：<span>{{ articleMsg.createTime }}</span>
            </p>
            <el-row class="btn-box">
              <button class="start" @click="toCollect">
                <img
                  src="@/assets/images/shoucang.png"
                  v-if="articleMsg.collectionId"
                  alt=""
                />
                <img src="@/assets/images/page-shoucang.png" v-else alt="" />
                <span v-if="articleMsg.collectionId" style="color: #ed1b23"
                  >收藏</span
                >
                <span v-else style="color: #333">收藏</span>
                <!-- <img
                  src="@/assets/images/shoucang.png"
                  v-if="articleMsg.collectionId"
                  alt=""
                />
                <img src="@/assets/images/collect-good.png" v-else alt="" /> -->
                <!-- <img src="@/assets/images/shoucang.png" alt="" />
                <span>收藏</span> -->
              </button>
              <button class="share" @click="shareVisible = true">
                <img src="@/assets/images/case/share.png" alt="" />
                <span>分享</span>
              </button>
            </el-row>
          </el-row>
          <!-- 图片展示 -->
          <el-row class="show">
            <!-- <img src="@/assets/images/case/bg.png" alt="" /> -->
            <video
              id="media"
              :src="articleMsg.videoUrl"
              controls
              width="920px"
              heigt="488px"
            ></video>
            <!-- <div class="banner">
              <video
                id="media"
                src="https://vd2.bdstatic.com/mda-kf3fdzne6yvig8rr/hd/mda-kf3fdzne6yvig8rr.mp4?v_from_s=hkapp-haokan-hbe&auth_key=1661324734-0-0-45dbc6862f2a7a2803e301a95906207a&bcevod_channel=searchbox_feed&pd=1&cd=0&pt=3&logid=2134472486&vid=5311965205702677243&abtest=103525_1&klogid=2134472486"
                controls
                width="400px"
                heigt="400px"
              ></video>
              <img
                class="banner-img"
                src="@/assets/images/home/video.png"
                alt=""
              />
            </div> -->
          </el-row>
          <!-- 文字展示 -->
          <el-row class="text-show">
            <p class="c6 f16" v-html="articleMsg.essayContent">
              <!-- {{
                articleMsg.essayContent ? articleMsg.essayContent : "暂无描述"
              }} -->
            </p>
          </el-row>
        </el-row>
        <!-- 案例 end -->
      </el-row>
    </el-row>
    <!-- 案例详情 end -->

    <Footer></Footer>
    <tell-us transform="translateY(-50%)" top="50%"></tell-us>

    <!-- 右侧保修 客服 -->
    <!-- <el-row class="fix-right">
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
    </el-row> -->
    <!-- 弹窗 start -->
    <!-- <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>13712345678</span>
        </p>
      </div>
    </el-dialog> -->
    <!-- 弹窗结束 -->
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="shareVisible"
      width="600px"
      :show-close="false"
      class="case-video-bassderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">分享</p>
        <img
          src="@/assets/images/home/close.png"
          @click="shareVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p class="text">{{ sharePos }}</p>
        <div class="btn-box">
          <button class="btn" :data-clipboard-text="sharePos" @click="copy">
            复制
          </button>
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import ClipboardJS from "clipboard";
import TellUs from "@/components/TellUs.vue";
export default {
  components: {
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    return {
      assderVisible: false, //客服弹窗
      shareVisible: false, //分享弹窗

      // 文章
      id: "", //文章id
      articleMsg: {}, //文章信息
      fileList: [], //附件信息

      sharePos: "", //分享地址
    };
  },

  mounted() {},
  created() {
    this.id = this.$route.query.id;
    // 获取分享地址
    this.sharePos = location.href;
    // 文章-通过id查询
    this.getArticeByID();
    // 通过文章id查询附件
    this.getArticeFile();
  },

  methods: {
    // 通过文章id查询附件
    getArticeFile() {
      let paramData = {
        essayId: this.id,
      };
      this.$API
        .getArticeFile(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.fileList = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 文章-通过id查询
    getArticeByID() {
      let paramData = {
        id: this.id,
      };
      this.$API
        .getArticeByID(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.articleMsg = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 分享弹窗复制
    copy() {
      let that = this;
      var clipboard = new ClipboardJS(".btn");

      clipboard.on("success", function (e) {
        that.$message({
          message: "复制成功",
          type: "success",
          duration: 1000,
        });
        e.clearSelection();
      });

      clipboard.on("error", function (e) {
        that.$message({
          message: "复制失败",
          type: "error",
        });
      });

      setTimeout(() => {
        this.shareVisible = false;
      }, 500);
    },

    // 控制客服弹窗
    assderHandle() {
      this.assderVisible = false;
    },
    // 去收藏
    toCollect() {
      if (!this.$store.state.token) {
        this.$message.info("登录后可进行收藏,请登录");
        return;
      }
      if (this.articleMsg.collectionId) {
        // 已经收藏了   取消收藏
        let paramData = {
          ids: Number(this.articleMsg.collectionId),
        };
        this.$API
          .userCollectionDelete(paramData)
          .then((res) => {
            this.isCollect = false;
            if (res.code == 1) {
              this.getArticeByID();
              this.$message({
                message: "已取消",
                type: "info",
                duration: 1000,
              });
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.isCollect = false;
      } else {
        // 还未收藏  去收藏
        let paramData = {
          collectionType: 2,
          sourceId: Number(this.id),
        };
        this.$API
          .addCollection(paramData)
          .then((res) => {
            if (res.code == 1) {
              this.getArticeByID();
              this.isCollect = true;
              this.$message({
                message: "收藏成功",
                type: "success",
                duration: 1000,
              });
            } else {
              this.$message.info(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // let paramData = {
      //   collectionType: 2,
      //   sourceId: Number(this.id),
      // };
      // this.$API
      //   .addCollection(paramData)
      //   .then((res) => {
      //     if (res.code == 1) {
      //       this.$message({
      //         message: "收藏成功",
      //         type: "success",
      //         duration: 1000,
      //       });
      //     } else {
      //       this.$message.info(res.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    // 去预览
    toPreview() {},
    // 去下载
    toDownload() {},
  },
};
</script>

<style lang="scss" scoped>
.case-video-contaner {
  .case-box {
    min-width: 1200px;
    width: 100%;
    border-top: 1px solid #ed1b23;
    background: #f8f8f8;
    .contaner {
      background: #f8f8f8;
    }
    .nav-box {
      padding-top: 36px;
      .pos {
        color: #333333;
        font-size: 16px;
      }
    }
  }
  //   案例
  .case {
    background-color: #ffffff;
    margin-top: 32px;
    margin-bottom: 40px;
    padding: 30px 40px;
    .header {
      border-bottom: 1px solid #eeeeee;
      .title {
        @include dot(2);
        font-size: 20px;
        color: #000000;
        line-height: 46px;
      }
      .date {
        margin-top: 16px;
        font-size: 14px;
        color: #999999;
        span {
          margin-left: 10px;
        }
      }
      .btn-box {
        margin-top: 20px;
        margin-bottom: 24px;
        text-align: right;
        button {
          width: 96px;
          height: 36px;
          line-height: 36px;
          background: #ffdee0;
          border-radius: 40px 40px 40px 40px;
          font-size: 14px;
          color: #ed1b23;
          span {
            position: relative;
            top: 2px;
            left: 2px;
          }
        }
        .start {
          img {
            width: 16px;
            height: 15px;
            vertical-align: middle;
          }
        }
        .share {
          margin-left: 20px;
          img {
            width: 12px;
            height: 15px;
            vertical-align: middle;
          }
        }
      }
    }
    // 图片展示
    .show {
      text-align: center;
      margin-top: 32px;
      position: relative;
      img {
        width: 920px;
        height: 488px;
      }
      .banner {
        position: absolute;
        left: 100px;
        top: 0px;
        width: 920px;
        height: 488px;
        background: #000000;
        border-radius: 20px;
        opacity: 0.3;
        display: flex;
        justify-content: center;
        align-items: center;
        .banner-img {
          width: 80px;
          height: 80px;
          margin-right: 0px !important;
        }
      }
    }
    // 文字展示
    .text-show {
      padding-bottom: 24px;
      p {
        margin-top: 32px;
        font-size: 16px;
        color: #666666;
      }
    }
    // 附件
    .file-area {
      margin-top: 30px;
      .header {
        border-bottom: none;
        margin-bottom: 20px;
        .title {
          cursor: pointer;
          height: 26px;
          img {
            width: 4px;
            height: 14px;
            margin-right: 10px;
          }
          span {
            font-size: 20px;
            color: #333333;
          }
        }
      }
      //   附件
      .file {
        width: 1120px;
        height: 60px;
        line-height: 60px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 24px;
        background: #f0f0f0;
        margin-bottom: 10px;
        p {
          font-size: 16px;
          color: #333333;
        }
        .btn-box {
          text-align: right;
          button {
            width: 68px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            border-radius: 28px 28px 28px 28px;
            font-size: 16px;
            color: #1577fc;
          }
          .start {
            border: 1px solid #1577fc;
            img {
              width: 16px;
              height: 15px;
              vertical-align: middle;
            }
          }
          .share {
            background: #1577fc;
            color: #ffffff;
            margin-left: 16px;
            img {
              width: 12px;
              height: 15px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
// 自定义弹窗样式
.case-video-bassderTc {
  // 对话框头部
  .el-dialog__header {
    padding: 0px !important;
  }
  // 对话框内容
  .el-dialog__body {
    padding: 0px !important;
  }
  display: flex;
  align-items: center;
  .title {
    padding-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 24px;
      right: 32px;
    }
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 0 !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  .ja-c {
    margin: 0 auto;
    margin-top: 24px;
    .text {
      width: 600px;
      box-sizing: border-box;
      padding: 0 32px;
      height: 45px;
      font-size: 16px;
      color: #666666;
      line-height: 24px;
      // margin-left: 62px;
      text-align: center;
    }
    .btn-box {
      width: 600px;
      text-align: center;
    }
    .btn {
      width: 140px;
      height: 42px;
      background: linear-gradient(96deg, #ed1b23 0%, #f15f64 100%);
      box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      margin-top: 37px;
      margin-bottom: 32px;
      // margin-left: 160px;
    }
  }

  .btnad {
    width: 112px;
    height: 40px;
    background: rgba(246, 247, 249, 1);
    border: 1px solid #ecedef;
    opacity: 1;
    border-radius: 20px;
  }
  .btnsad {
    color: #333;
    width: 112px;
    height: 40px;
    background: rgba(255, 195, 44, 1);
    opacity: 1;
    border-radius: 20px;
  }
}
// 右侧保修 客服
// .fix-right {
//   cursor: pointer;
//   width: 88px;
//   height: 90px;
//   border: 1px solid #ebebeb;
//   position: fixed;
//   z-index: 1000;
//   right: 0;
//   bottom: 266px;
//   // top: 50%;
//   // transform: translateY(-50%);
//   .item1 {
//     background-color: #ffffff;
//   }
//   .item2 {
//     background-color: #ed1b23;
//     z-index: 999;
//   }
//   .item1,
//   .item2 {
//     height: 90px;
//     text-align: center;
//     box-sizing: border-box;
//     padding-top: 9px;
//     // padding-bottom: 16px;
//     img {
//       width: 24px;
//       height: 24px;
//     }
//     p {
//       color: #757575;
//       font-size: 14px;
//       margin-top: 12px;
//     }
//   }
//   .item2 p {
//     color: #ffffff;
//   }
// }
// 自定义弹窗样式
// .assderTc {
//   display: flex;
//   align-items: center;
//   .title {
//     padding-top: 32px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     font-size: 22px;
//     font-weight: bold;
//     color: #333333;
//     img {
//       width: 32px;
//       height: 32px;
//       position: absolute;
//       top: 32px;
//       right: 32px;
//     }
//   }
//   ::v-deep .el-dialog__header {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 0 !important;
//   }
//   ::v-deep .el-dialog__body {
//     padding: 0;
//   }
//   ::v-deep .el-dialog__footer {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 10px;
//   }
//   .ja-c {
//     height: 311px;
//     box-sizing: border-box;
//     padding-left: 214px;
//     padding-top: 52px;
//     p {
//       display: flex;
//       align-items: center;
//       margin-bottom: 32px;
//       img {
//         width: 30px;
//         height: 30px;
//       }
//       span {
//         font-size: 14px;
//         color: #333333;
//         margin-left: 16px;
//       }
//     }
//   }

//   .btnad {
//     width: 112px;
//     height: 40px;
//     background: rgba(246, 247, 249, 1);
//     border: 1px solid #ecedef;
//     opacity: 1;
//     border-radius: 20px;
//   }
//   .btnsad {
//     color: #333;
//     width: 112px;
//     height: 40px;
//     background: rgba(255, 195, 44, 1);
//     opacity: 1;
//     border-radius: 20px;
//   }
// }
</style>
